<template>
  <div v-if="$route" id="app" class="flex-column">
    <component :is="layout" />
  </div>
</template>

<script>
const defaultLayout = "bordered-page";
export default {
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + "-layout";
    },
  },
};
</script>

<style lang="scss">
@import "@/style/global";
@import "@/style/mixins";
@import "@/style/variables";

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
}

body {
  background: #f8f8f8;
  @include noto;
  position: relative;
  padding: 0;
  margin: 0;
}

#app {
  min-height: 100vh;
}
</style>

<template>
  <section class="log-in-switch-page">
    <h1>Choose your Lasting account</h1>
    <a href="/couples/"
      ><option-button class="button">
        <img
          :src="require(`@/assets/lasting_couples_heart_icon.svg`)"
          alt="Lasting for Couples Icon"
          width="80"
          height="80"
        />
        <div class="text">
          <header>Couples</header>
          Guided relationship counseling
        </div>
      </option-button></a
    >
    <a href="/parenting/"
      ><option-button class="button">
        <img
          :src="require(`@/assets/lasting_parenting_icon.svg`)"
          alt="Lasting for Parenting Icon"
          width="80"
          height="80"
        />
        <div class="text">
          <header>Parents</header>
          Guided parenting program
        </div></option-button
      ></a
    >
  </section>
</template>

<script>
import OptionButton from "@/components/OptionButton";

export default {
  name: "LogInSwitchPage",
  components: { OptionButton },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixins";
@import "@/style/variables.scss";

.log-in-switch-page {
  h1 {
    margin-bottom: 32px;
  }

  a:hover {
    text-decoration: none;
  }

  .button {
    @include flex-row;
    line-height: 24px;
    color: $black;
    padding: 24px;

    .text {
      @include flex-column;
      font-size: 16px;
    }

    img {
      margin-right: 20px;
    }

    header {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 6px;
    }
  }
}
</style>

import Vue from "vue";
import App from "@/App.vue";
import VueRouter from "vue-router";
import user from "@/user";
import router from "@/index/router";
import BlankLayout from "@/layout/Blank";
import BorderedPageLayout from "@/layout/BorderedPage";

Vue.config.productionTip = false;

Vue.component("BlankLayout", BlankLayout);
Vue.component("BorderedPageLayout", BorderedPageLayout);

Vue.use(VueRouter);
Vue.use(user);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

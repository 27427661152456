import axios from "axios";
import Vue from "vue";
import Cookies from "js-cookie";
import env from "@/env";

let jwtName = null;
let sessionCookieName = null;
class User {
  constructor() {
    this.user = null;
    this.userPromise = null;
    axios.defaults.baseURL = env.getEnvVar("VUE_APP_REMOTE_URL");
    jwtName = `jwt-${env.getEnv()}`;
    sessionCookieName = `session-${env.getEnv()}`;
    this.jwt = localStorage.getItem(jwtName);
    if (this.jwt && Cookies.get(sessionCookieName)) {
      this.setJwt(this.jwt);
      this.userPromise = this.getProfile();
    } else {
      localStorage.removeItem(jwtName);
    }
  }

  isLoggedIn() {
    return this.jwt != null;
  }

  logOut() {
    this.user = null;
    this.userPromise = null;
    this.jwt = null;
    localStorage.removeItem(jwtName);
    Cookies.remove(sessionCookieName);
  }

  setJwt(jwt) {
    localStorage.setItem(jwtName, jwt);
    axios.defaults.headers.common["Authorization"] = jwt;
    Cookies.set(sessionCookieName, new Date(), { expires: 0.01 });
    if (!this.user) {
      this.getProfile();
    }
  }

  getProfile() {
    if (!this.userPromise)
      this.userPromise = axios
        .get("/users/profile")
        .then(this.userLoaded.bind(this))
        .catch(this.userLoadError.bind(this));
    return this.userPromise;
  }

  getSubscriptionPricing() {
    if (this.user) {
      const pricing = this.user["subscription-info"]
        ? this.user["subscription-info"]["next-billing-amount"]
        : null;
      return pricing !== null && !Number.isNaN(Number(pricing))
        ? (pricing / 100).toFixed(2)
        : null;
    }
    return null;
  }

  getSubscriptionInterval() {
    if (this.user) {
      return this.user["subscription-info"]
        ? this.user["subscription-info"]["billing-frequency"]
        : null;
    }
    return null;
  }

  userLoaded({ data }) {
    this.user = { ...data.data.attributes, id: data.data.id };
    this.userPromise = null;
    return Promise.resolve(this.user);
  }

  userLoadError() {
    this.userPromise = null;
    return Promise.reject();
  }

  logIn(email, password) {
    return axios
      .post("/user_token", {
        auth: { email, password },
      })
      .then(this.logInComplete.bind(this));
  }

  logInComplete(response) {
    const data = response.data;
    this.user = data.user;
    this.jwt = data.jwt;
    this.setJwt(this.jwt);
    return Promise.resolve(this.user);
  }

  resetPassword(email) {
    return axios.post("/passwords", {
      email,
    });
  }

  cancelSubscription() {
    return axios.post("/subscription/cancel");
  }

  subscriptionRescue() {
    return axios.post("/subscription/rescue");
  }

  hasActiveSubscription() {
    return (
      this.user &&
      this.user["subscription-info"] &&
      !this.user["subscription-info"]["subscription-end-date"]
    );
  }
}

const user = new User();
user.install = function () {
  Object.defineProperty(Vue.prototype, "$user", {
    get() {
      return user;
    },
  });
};

export default user;

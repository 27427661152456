export const isFamily = () => window.location.href.includes("parenting");
export const isCouples = () => window.location.href.includes("couples");

export default {
  getEnv: function () {
    let defaultEnv = "LC";
    let env = defaultEnv;

    // check and switch env
    if (isFamily()) env = "LP";
    return env;
  },
  getEnvVar: function (name) {
    let val = process.env[name];
    if (val !== undefined) {
      return val;
    }

    return process.env[name.replace("VUE_APP_", `VUE_APP_${this.getEnv()}_`)];
  },
};

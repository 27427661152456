import VueRouter from "vue-router";

const router = new VueRouter({
  routes: [
    {
      path: "*",
      redirect: "/",
    },
    {
      path: "/",
      name: "account-switch",
      meta: {
        showBack: false,
      },
      component: () => import("@/index/pages/LogInSwitch.vue"),
    },
  ],
});

export default router;

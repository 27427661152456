<template>
  <div
    :class="{
      'option-button': true,
      selected: isSelected,
      'is-loading': isLoading,
      disabled: disabled || isLoading,
      'is-clickable': isClickable,
    }"
    @click="selectItem"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    isSelected: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    isClickable: { type: Boolean, default: true },
  },
  methods: {
    selectItem() {
      if (!this.disabled) this.$emit("itemSelected");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixins";
@import "@/style/variables.scss";

.option-button {
  min-height: 80px;
  background: #ffffff;
  // border: 0.8px solid #dbdbdb;
  box-shadow: 1px 3px 12px 2px rgba(#e0e0e0, 0.5);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 28px 20px 27px 25px;
  text-align: left;
  margin-bottom: 24px;
  &.is-clickable {
    cursor: pointer;
  }

  &.disabled {
    color: $dark-gray;
    cursor: auto;
  }

  &.selected {
    background: #ebebeb;
    border-color: #ebebeb;
  }

  &.is-loading {
    padding-right: 40px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 0px;
      height: 0px;
      border-radius: 50%;
      right: 20px;
      top: 50%;
      border: 2px solid rgba(255, 255, 255, 0.4);
      border-right: 2px solid #000;
      animation: rotate360 0.5s infinite linear, exist 0.1s forwards ease;
      z-index: 5;
    }

    @keyframes rotate360 {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes exist {
      100% {
        width: 12px;
        height: 12px;
        margin: -8px 5px 0 0;
      }
    }
  }
}

@media (hover: hover) {
  .option-button.is-clickable:hover {
    background: #ebebeb;
  }
}
</style>

<template>
  <span class="blank-layout-container-thing">
    <router-view />
  </span>
</template>

<script>
export default {
  name: "BlankLayout",
};
</script>

<style lang="scss">
body {
  background: #fff !important;
}
</style>

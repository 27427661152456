<template>
  <div class="page-footer">
    <div class="brief">
      <div class="caption">
        Need help? Contact us:
        <a class="mail" :href="mailContact">{{ contactMail }}</a>
      </div>
      <div class="sub-caption">We’ll get back to you within 24 hours.</div>
    </div>

    <div class="separator"></div>

    <div class="footer-intro">Created with ❤︎ by the Lasting Crew</div>

    <div class="social-buttons">
      <a href="https://www.instagram.com/getlasting/">
        <img src="/assets/instagram-dark.svg" alt="instagram" />
      </a>

      <a href="https://www.facebook.com/getlasting/">
        <img src="/assets/facebook-dark.svg" alt="facebook" />
      </a>
    </div>

    <div class="navigation">
      <a :href="siteUrl + '/'"> Home </a>
      <a :href="siteUrl + '/about-us/'"> About Us </a>
      <a :href="siteUrl + '/therapists/'"> For Therapists </a>
      <a :href="siteUrl + '/help/'"> Help Center </a>
      <a :href="siteUrl + '/terms/'"> Terms of Use </a>
      <a :href="siteUrl + '/privacy-policy/'"> Privacy Policy </a>
      <a :href="siteUrl + '/accessibilitystatement/'"> Accessibility </a>
      <a :href="siteUrl + '/caprivacy/'"> CA Privacy </a>
    </div>

    <div class="copy-right text-center">©2022 Lasting, a Talkspace company</div>
  </div>
</template>

<script>
import env, { isCouples, isFamily } from "../../env";
export default {
  name: "LayoutFooter",
  data() {
    return {
      contactMail: "",
      siteUrl: "https://www.getlasting.com",
      userId: 0,
    };
  },
  computed: {
    helpMail() {
      return `mailto:${this.contactMail}`;
    },
    mailContact() {
      const subject = `Help with account.getlasting.com`;
      const newLineString = "%0D%0A";
      const body = `Please describe the issue with as much detail as possible! We'll get back to you within 24 hours.${newLineString} ${newLineString} ${newLineString} ${newLineString} ${newLineString}---------------------${newLineString}Some information to help us troubleshoot${newLineString}User ID: ${this.userId}${newLineString}Current URL:${window.location}`;
      return `${this.helpMail}?subject=${subject}&body=${body}`;
    },
  },
  watch: {
    $route() {
      const vuePrefix = "VUE_APP_";
      const contactEmailVarName = "CONTACT_EMAIL";
      this.userId = this.$user && this.$user.user ? this.$user.user.id : "";

      this.contactMail = env.getEnvVar("VUE_APP_CONTACT_EMAIL");
      if (isCouples())
        this.contactMail = env.getEnvVar(
          `${vuePrefix}LC_${contactEmailVarName}`
        );
      if (isFamily())
        this.contactMail = env.getEnvVar(
          `${vuePrefix}LP_${contactEmailVarName}`
        );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;700&display=swap");
.page-footer {
  background: $lighter-gray;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $black;
  flex-direction: column;
  font-family: "Jost", sans-serif;

  @media (max-width: 576px) {
    padding: 40px 25px;
  }

  a {
    text-decoration: underline;
    color: $black;
  }
}

.separator {
  width: 16px;
  height: 1px;
  background-color: #979797;
  margin: 29px 0 33px 0;
}

.footer-intro {
  font-size: 16px;
}

.social-buttons {
  margin: 28px 0 34px 0;
  a {
    margin-right: 18px;
    display: inline-block;
    line-height: 12px;

    img {
      width: 18px;
      height: 18px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.brief {
  text-align: center;
  .caption {
    font-size: 16px;
    color: #000;
  }

  .sub-caption {
    font-size: 14px;
    margin-top: 8px;
  }

  a {
    font-weight: 700;
    color: $orange;
    text-decoration: none;
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a {
    margin-bottom: 16px;
    font-size: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.copy-right {
  margin-top: 40px;
  font-size: 16px;
}
</style>

import Vue from "vue";
import env from "@/env";
import user from "@/user";

const mixpanel = require("mixpanel-browser");

const DEFAULT_EVENT_PROPS = { event_source: "Web" };

class Tracker {
  constructor() {
    mixpanel.init(env.getEnvVar("VUE_APP_MIXPANEL_TOKEN"), {
      batch_requests: false,
      ignore_dnt: true,
    });
  }

  registerMixpanel(props) {
    mixpanel.register(props);
  }

  aliasMixpanel(id) {
    if (id) {
      mixpanel.alias(id);
    }
  }

  trackMixpanel(name, data = {}) {
    const pricing = {
      current_plan_price: null,
    };
    const subPrice = user.getSubscriptionPricing();
    if (subPrice) {
      pricing[
        "current_plan_price"
      ] = `${subPrice}/${user.getSubscriptionInterval()}`;
    }
    mixpanel.track(name, Object.assign({}, pricing, DEFAULT_EVENT_PROPS, data));
  }

  identify(id) {
    if (id) {
      mixpanel.identify(id);
    }
  }

  trackEvent(event, props = {}) {
    this.trackMixpanel(event, props);
  }

  trackLogIn(subscription = {}) {
    this.trackEvent("Login", subscription);
  }

  trackCancelStarted() {
    this.trackEvent("Subscription Cancellation Started");
  }

  trackCancel() {
    this.trackEvent("Subscription Cancellation Completed");
  }

  trackCancelReasonSelected(reason) {
    this.trackEvent("Cancellation reason selected", reason);
  }

  trackPlanChanged(data) {
    this.trackEvent("Stripe Subscription Plan Changed", data);
  }

  trackCancellationFeedback(data) {
    this.trackEvent("Cancellation Feedback", data);
  }
}

const tracker = new Tracker();
tracker.install = function () {
  Object.defineProperty(Vue.prototype, "$tracker", {
    get() {
      return tracker;
    },
  });
};

export default tracker;

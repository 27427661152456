<template>
  <div class="header-bar">
    <a v-if="showBack && backLink" class="back" :href="backLink" />
    <a
      v-if="showBack && !backLink"
      class="back"
      href="#"
      @click.prevent="$router.go(-1)"
    />
    <a href="/"
      ><div
        :class="{
          'nav-bar-logo': true,
          lasting: showLastingLogo,
          talkspace: !showLastingLogo,
        }"
      ></div
    ></a>
  </div>
</template>

<script>
export default {
  name: "HeaderBar",
  props: {},
  data() {
    return { showBack: true, backLink: null, showLastingLogo: true };
  },
  watch: {
    $route(to) {
      this.showLastingLogo =
        to.meta && typeof to.meta.showLastingLogo !== "undefined"
          ? to.meta.showLastingLogo
          : true;
      this.showBack = !(to.meta && to.meta.showBack === false);
      this.backLink = to.meta && to.meta.backLink;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";
@import "@/style/mixins";

.header-bar {
  width: 100%;
  color: $black;
  padding: 5px 20px;
  height: 70px;
  border-top: rgba(177, 177, 177, 0.25) 1px solid;
  box-shadow: 0px 2px 3px rgba(177, 177, 177, 0.25);
  @include flex-row;
  justify-content: center;

  .nav-bar-logo {
    &.lasting {
      background-image: url("../assets/lasting_from_ts_logo_black.svg");
      background-size: 80px auto;
      width: 80px;
    }

    &.talkspace {
      background-image: url("../assets/talkspace_logo_black.svg");
      background-size: 100px auto;
      width: 100px;
    }

    background-repeat: no-repeat;
    background-position: center center;
    height: 60px;
  }

  .back {
    background-image: url("../assets/back_arrow_black.svg");
    background-repeat: no-repeat;
    background-position: center left;
    position: absolute;
    left: 0px;
    top: 0px;
    display: block;
    color: $black;
    font-size: 20px;
    text-decoration: none;
    width: 50px;
    height: 50px;
    margin: 12px 25px;
    transition: all 0.5s ease-in-out;
    z-index: 2;
  }
}
</style>

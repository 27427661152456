<template>
  <div class="page-container">
    <div class="bordered-page flex-column">
      <header-bar class="header" />
      <section
        :class="{
          'inner-block': useInnerBlock,
          'flex-column': !useInnerBlock,
        }"
      >
        <router-view :key="$route.path" class="flex-column" />
      </section>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import HeaderBar from "@/components/HeaderBar";
import PageFooter from "@/layout/components/Footer";

export default {
  name: "BorderedPageLayout",
  components: { HeaderBar, PageFooter },
  data() {
    return { useInnerBlock: true };
  },
  watch: {
    $route: function (newVal) {
      this.setInnerBlock(newVal);
    },
  },
  methods: {
    setInnerBlock(route) {
      const val = route.meta.useInnerBlock;
      this.useInnerBlock =
        typeof val === "undefined" || val === null ? true : val;
    },
  },
};
</script>

<style lang="scss">
@import "@/style/mixins";
@import "@/style/variables";

.bordered-page {
  width: 950px;
  margin: 68px auto 56px;
  min-height: calc(100vh - 92px - 93px - 56px);
  position: relative;
  box-shadow: 0px 3px 5px rgba(160, 159, 159, 0.5);
  flex: 1;

  h1 {
    margin-top: 0;
    font-size: 20px;
    line-height: 30px;
  }

  p {
    font-size: 16px;
  }

  input[type="text"],
  input[type="password"] {
    height: 65px;
    border: 1.5px solid $light-gray;
    font-size: 17.5px;
    margin-top: 17px;
    border-radius: 3px;
  }

  .form-group {
    .error {
      display: none;
    }
  }

  .form-group-error {
    .error {
      display: block;
      text-align: left;
    }
  }

  .inner-block {
    flex: 1;
    padding: 55px 22px 30px;
    width: 480px;
    margin: 0 auto;
    text-align: center;
    z-index: 0;
    @include flex-column;
  }

  .header {
    flex: 0;
  }
}

@media only screen and (max-width: $breakpoint) {
  .bordered-page {
    margin: 0;
    width: 100%;
    box-shadow: none;
    min-height: calc(100vh - 92px - 28px);

    .inner-block {
      width: 100%;
    }
  }
}
</style>
